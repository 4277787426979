import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Container, Grid } from '@mui/material';
import { customFetch, parseResponse } from './components/http';
import config from './config';
import { toast } from 'react-toastify';

function Bind() {
  const [publicaccounts, setPublicAccounts] = useState([]);

  // Get user
  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = `${config.BASE_URL}/user/publicaccounts`;
      try {
        const response = await customFetch(apiUrl, { method: 'GET' });
        const result = await parseResponse(response);
        if (Array.isArray(result)) {
          setPublicAccounts(result);
        }
        console.log(result);
      } catch (error) {
        console.error('Error fetching user data: ', error);
      }
    };

    fetchData();
  }, []);

  const handleUnbind = async (appId) => {
    try {
      const response = await customFetch(`${config.BASE_URL}/bind/unbind`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(appId),
      }, true);

      const data = await parseResponse(response);
      if (!response.ok) {
        console.error('解绑失败详情:', data);
        throw new Error(`解绑失败: ${data}`);
      }

      // 解绑成功后更新 publicAccounts
      setPublicAccounts((prevAccounts) => prevAccounts.filter(account => account.appId !== appId));
      toast.success("解绑成功！");
    }
    catch {
      toast.error("解绑失败！");
    }
  };

  const handleAddPublicAccount = () => {
    const newWindow = window.open('https://wxcomponent-111020-4-1327218865.sh.run.tcloudbase.com/#/authorize', '_blank');

    const checkWindowClosed = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(checkWindowClosed);
        window.location.reload();
      }
    }, 1000);
  };

  return (
    <Container>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {publicaccounts?.map((account, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid black',
                borderRadius: '8px',
                padding: '8px 16px'
              }}
            >
              <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                <Box
                  component="img"
                  src={account.imageUrl || 'img/wechatlogo.webp'}
                  alt={account.nickname}
                  onError={(e) => { e.target.onerror = null; e.target.src = 'img/wechatlogo.webp'; }}
                  sx={{
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    marginRight: '8px'
                  }}
                />
                <Typography>{account.nickname}</Typography>
              </Box>
              <Button variant="outlined" onClick={() => handleUnbind(account.appId)}>解绑</Button>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Button
        variant="contained"
        onClick={handleAddPublicAccount}
        sx={{
          mt: 2,
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >
        新增绑定公众号（新增公众号可获得10个火种）
      </Button>
    </Container>
  );
}

export default Bind;
