// 假设有一个全局的 BrowserWindow 实例
// let win = new BrowserWindow({ show: false }); // 设置为不显示窗口

export async function fetchUrlContent(url) {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const html = await response.text();  // 获取HTML字符串
        return parseAndProcessHTML(html);
    } catch (error) {
        console.error('Error fetching URL:', url, error);
       // loadURLInElectron(url);
        return null;
    }
}

// async function loadURLInElectron(url) {
//     if (!win) {
//         win = new BrowserWindow({ show: false }); // 确保有窗口实例
//     }

//     let view = new BrowserView();
//     win.setBrowserView(view);
//     view.setBounds({ x: 0, y: 0, width: 800, height: 600 }); // 设置 BrowserView

//     return new Promise((resolve, reject) => {
//         view.webContents.loadURL(url);
//         view.webContents.once('did-finish-load', async () => {
//             try {
//                 const html = await view.webContents.executeJavaScript('document.documentElement.outerHTML');
//                 resolve(parseAndProcessHTML(html));
//             } catch (jsError) {
//                 reject(`Error executing JavaScript in Electron: ${jsError}`);
//             }
//         });

//         view.webContents.once('did-fail-load', (event, errorCode, errorDescription) => {
//             reject(`Failed to load URL in Electron: ${errorDescription}`);
//         });
//     });
// }

async function parseAndProcessHTML(html) {
    // 创建一个新的DOMParser来解析HTML
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // 移除所有 script 和 style 元素
    doc.querySelectorAll('script, style').forEach(el => el.remove());
    doc.querySelectorAll('[class*="recommend"]').forEach(el => el.remove()); // 去除class中包含recommend的元素

    // 提取所有文本内容
    const rawText = doc.body.innerText;  // 使用innerText来获取纯文本，会自动忽略HTML标签
    return processText(rawText);
}

function processText(rawText) {
    // 正则表达式用于分割文本
    const pattern = /(?<![\w\p{P}]) | (?![\w\p{P}])|\r|\n|\|/gu;
    // 分割文本
    const lines = rawText.split(pattern);

    // 过滤和清理每行
    const cleanedLines = lines
        .map(line => line.trim()) // 去除每行的首尾空白字符
        .filter(line => line.length > 0) // 过滤掉空行
        .filter(line => countCharacters(line) > 4); // 过滤掉字符数小于或等于3的行

    return cleanedLines;
}

function countCharacters(input) {
    // 正则表达式匹配英文单词、中文字符和其他非空白符号
    const regex = new RegExp("\\b[A-Za-z0-9]+\\b|[\\u4e00-\\u9fff]|[^\\s\\w\\u4e00-\\u9fff]", "g");
    // 获取所有匹配项
    const matches = input.match(regex) || [];
    // 返回匹配数量
    return matches.length;
}
